import "./Tenants.scss";
import t1 from "../../Assets/Tenants/t1.png";
import t2 from "../../Assets/Tenants/t2.png";
import t3 from "../../Assets/Tenants/t3.png";
import t4 from "../../Assets/Tenants/t4.png";
import t5 from "../../Assets/Tenants/t5.png";
import t6 from "../../Assets/Tenants/t6.png";
import t7 from "../../Assets/Tenants/t7.png";
import t8 from "../../Assets/Tenants/t8.png";
import t9 from "../../Assets/Tenants/t9.png";
import t10 from "../../Assets/Tenants/t10.png";

const logos = [t1, t2, t3, t4, t5, t6, t7, t8, t9, t10];

const Tenants = () => {
  return (
    <section className="section-tenants" id="tenants">
      <div className="container-tenants">
        <h3 className="title">OUR TENANTS</h3>
        <div className="logos">
          {logos.map((logo, index) => (
            <div className="logo-box">
              <img key={index} src={logo} alt="logo-tenant" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Tenants;

import "./Product.scss";
import CardProduk from "../../Component/Card/CardProduk";

const Product = ({ productTitle, dataProduk, imageMap }) => {
  return (
    <section className="section-product" id="product-commercial">
      <div className="container-product">
        <h2 className="title">{productTitle}</h2>
        <div className="container-card">
          {dataProduk.map((data, index) => (
            <CardProduk
              key={index}
              carousel={imageMap[data.imagesKey]}
              cardID={data.cardID}
              title={data.title}
              downPaymentType={data.downPaymentType}
              price={data.price}
              details={data.details}
              link={data.link}
              whatsappLink={data.whatsappLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Product;

import "./Projects.scss";
import p2 from "../../Assets/Project/p2 (2).jpeg";
import p8 from "../../Assets/Project/p8.jpeg";
import p9 from "../../Assets/Project/p9.jpeg";
import p11 from "../../Assets/Project/p11.jpeg";
import p12 from "../../Assets/Project/p11.jpeg";
import p13 from "../../Assets/Project/p13.png";
import p14 from "../../Assets/Project/p14.jpeg";
import p18 from "../../Assets/Project/p18.jpeg";
import p19 from "../../Assets/Project/p19.jpeg";
import p20 from "../../Assets/Project/p20.jpeg";
import p21 from "../../Assets/Project/p21.jpeg";
import p22 from "../../Assets/Project/p22.jpeg";
import p23 from "../../Assets/Project/p23.jpeg";
import p24 from "../../Assets/Project/p24.jpeg";
import p25 from "../../Assets/Project/p25.jpeg";
import p26 from "../../Assets/Project/p26.jpeg";
import p27 from "../../Assets/Project/p27.jpeg";
import p28 from "../../Assets/Project/p28.jpeg";

const projectItem = [
  { image: p2, title: "Akasa" },
  { image: p8, title: "BSD International Automotive Center (BIAC)" },
  { image: p9, title: "Iconix Studio Loft" },
  { image: p11, title: "Daikanyama" },
  { image: p12, title: "Greenwich Business Park" },
  { image: p13, title: "91 District" },
  { image: p14, title: "Ruko Campton BSD City" },
  { image: p18, title: "Foresta Business Loft" },
  { image: p19, title: "TabeSpot" },
  { image: p20, title: "Virginia Arcade" },
  { image: p21, title: "92 Avenix" },
  { image: p22, title: "Digital Loft" },
  { image: p23, title: "ICE Business Park" },
  { image: p24, title: "Icon Business Park 5" },
  { image: p25, title: "Ruko Loka 65" },
  { image: p26, title: "Ruko Pasar Modern Timur BSD" },
  { image: p27, title: "YC Hub" },
  { image: p28, title: "Ruko Golden Madrid X" },
];
const Projects = () => {
  return (
    <section className="section-project" id="past-projects">
      <div className="container">
        <h2 className="title">Past Projects</h2>
        <div className="project">
          {projectItem.map((item, index) => (
            <div className="project-item" key={index}>
              <img src={item.image} alt="" className="project-img" />
              <div className="project-title">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;

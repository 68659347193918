import "../Pages.scss";
import heroImg from "../../Assets/Hero/VAP-hero.jpeg";
import aboutImg from "../../Assets/About/VAP-about.jpeg";
import flyerImg from "../../Assets/Flyer/VAP-flyer.webp";
import map from "../../Assets/Map/VAP-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  promenadeImages,
  promenadeHookImages,
  promenadeBackyardImages,
  plazaImages,
} from "../../Assets/Product/VAP/VAPImages";
const imageMap = {
  promenadeImages,
  promenadeHookImages,
  promenadeBackyardImages,
  plazaImages,
};
const VAP = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"VIRGINIA ARCADE PROMENADE"}
        imgAlt={"VAP Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"VAP About Image"}
        aboutTitle={"VIRGINIA ARCADE PROMENADE"}
        aboutDescription={
          "VA Promenade adalah fase kedua shophouse komersial di Virginia Arcade dengan desain modern yang menarik. Terletak strategis di Ruko Virginia Arcade, BSD City, dengan harga mulai Rp. 2.411.096.590, tempat ini menawarkan fasilitas seperti keamanan, CCTV, dan taman yang indah. Dekat dengan berbagai area penting seperti BSD Square, kantor-kantor, hotel, dan tempat rekreasi, VA Promenade adalah pilihan ideal bagi bisnis yang ingin maju di BSD City."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Virginia%20Arcade%20Promenade)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN VIRGINIA ARCADE PROMENADE"}
        pointData={{
          poin1: ["Tanpa DP", "Discount Insentif PPN 220Juta", "Subsidi DP"],
          poin2: [],
        }}
        waLink="https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20Virginia%20Arcade%20Promenade)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.VAPcards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"VAP Location Image"}
        pointData={[
          "Tol Serbaraja 3 menit.",
          "Tol JORR 2 (BSD-Kunciran-Airport) 3 menit.",
          "Serpong – Pamulang – Cinere 3 menit.",
          "Pasar Modern BSD 3 menit.",
          "Halte feeder busway 5 menit.",
          "Soekarno-Hatta International Airport 20 menit.",
        ]}
      />
    </div>
  );
};

export default VAP;

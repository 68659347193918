import "../Pages.scss";
import heroImg from "../../Assets/Hero/asterra-hero.webp";
import aboutImg from "../../Assets/About/asterra-about.webp";
import flyerImg from "../../Assets/Flyer/astera.webp";
import map from "../../Assets/Map/asterra-map.webp";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  rukoImages,
  studioImages,
} from "../../Assets/Product/Asterra/asterraImages";
const imageMap = {
  rukoImages,
  studioImages,
};
const Asterra = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"ASTERRA BUSINESS PARK"}
        imgAlt={"Asterra Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"asterra About Image"}
        aboutTitle={"ASTERRA BUSINESS PARK "}
        aboutDescription={
          "Asterra Business Park adalah kawasan komersial terbaru yang diluncurkan oleh Sinar Mas Land di BSD City, Indonesia, pada awal tahun 2025. Dengan luas lahan sekitar 11.000 m², Asterra Business Park menawarkan dua tipe bangunan, yaitu shophouse dan studio loft, yang dirancang untuk memenuhi kebutuhan bisnis dan gaya hidup modern. Bangunan ini memiliki tiga hingga empat lantai dengan desain arsitektur yang menggabungkan elemen mid-century, semi-retro, dan futuristik, memberikan tampilan yang elegan dan timeless"
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Asterra%20Business%20Park)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN ASTERRA BUSINESS PARK"}
        pointData={{
          poin1: ["Diskon 10%", "Free DP"],
          poin2: ["Free Bunga", "Voucher Belanja 5 Juta"],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20Asterra)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"UNIT TYPE"}
        dataProduk={dataProduk.Asterracards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Asterra Business Park"}
        pointData={[
          "1 Menit ke Pom Bensin BP",
          "3 Menit ke Eastvara Mall",
          "5 Menit ke Grand Lucky",
          "5 Menit ke Genesis Global School",
          "7 Menit ke ICE BSD",
          "7 Menit ke QBig BSD",
          "10 Menit ke Alam Sutera",
          "10 Menit ke Summarecon Mall Serpong",
        ]}
      />
    </div>
  );
};

export default Asterra;

import "./App.scss";
import Home from "./Pages/Home/Home";
import Navbar from "./Component/Navbar/Navbar";
import Footer from "./Component/Footer/Footer";
import VAP from "./Pages/Virginia Arcade Promenade/VAP";
import Latinos from "./Pages/Latinos/Latinos";
import Loop from "./Pages/The Loop/Loop";
import Vanya from "./Pages/Vanya Avenue/Vanya";
import WVP from "./Pages/West Village Park/WVP";
import Xlane from "./Pages/Xlane/Xlane";
import NU from "./Pages/Northridge Ultimate/NU";
import Delrey from "./Pages/Delrey/Delrey";
import TTX from "./Pages/Taman Tekno X/TTX";
import CC from "./Pages/Capital Cove/CC";
import NPT from "./Pages/North Point Two/NPT";
import NBS from "./Pages/Navapark Business Suites/NBS";
import Asterra from "./Pages/Asterra/Asterra";
import Iconix from "./Pages/Iconix Infinite/Iconix-infinite";
import Privacy from "./Pages/Privacy/Privacy";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/virginia-arcade-promenade" element={<VAP />} />
        <Route path="/latinos" element={<Latinos />} />
        <Route path="/the-loop" element={<Loop />} />
        <Route path="/vanya-avenue" element={<Vanya />} />
        <Route path="/west-village-park" element={<WVP />} />
        <Route path="/xlane" element={<Xlane />} />
        <Route path="/northridge-ultimate" element={<NU />} />
        <Route path="/delrey" element={<Delrey />} />
        <Route path="/taman-tekno-x" element={<TTX />} />
        <Route path="/capital-cove" element={<CC />} />
        <Route path="/north-point-two" element={<NPT />} />
        <Route path="/navapark-business-suites" element={<NBS />} />
        <Route path="/iconix-infinite" element={<Iconix />} />
        <Route path="/asterra-business-park" element={<Asterra />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>

      <Footer />
    </Router>
  );
}

export default App;

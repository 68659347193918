import "../Pages.scss";
import heroImg from "../../Assets/Hero/latinos-hero.jpeg";
import aboutImg from "../../Assets/About/latinos-about.jpeg";
import flyerImg from "../../Assets/Flyer/latinos-flyer.webp";
import map from "../../Assets/Map/latinos-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import { t1Images, t2Images } from "../../Assets/Product/Latinos/LatinosImages";
const imageMap = {
  t1Images,
  t2Images,
};
const Latinos = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"LATINOS"}
        imgAlt={"Latinos Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"Latinos About Image"}
        aboutTitle={"LATINOS"}
        aboutDescription={
          "Berlokasi strategis, Latinos Business District menawarkan ruko-ruko eksklusif dengan desain modern yang dilengkapi parkir luas. Konsep ruko hybrid yang unik, memungkinkan Anda menggabungkan tempat usaha dan hunian dalam satu bangunan. Dengan tiang berbentuk 'T' yang khas, ruko Anda akan mudah dikenali dan menarik perhatian pelanggan."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Latinos)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN LATINOS"}
        pointData={{
          poin1: ["Subsidi DP", "Subsidi Biaya KPR", "Free IPL 12 bulan"],
          poin2: ["Insentif Buka Usaha 80Jt", "Free Smart Door Lock"],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20Latinos)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.Latinoscards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Latinos Location Image"}
        pointData={[
          "Tol Serpong–Jakarta, Stasiun Rawa Buntu 2 menit.",
          "Bandara Soekarno Hatta 28 menit.",
          "The Breeze BSD City 9 menit.",
          "AEON Mall 11 menit.",
          "QBIG BSD City, BSD Plaza 14 menit.",
          "Eka Hospital BSD 7 menit.",
          "RS Medika, RS Hermina Serpong 12 menit.",
          "Taman Kota 2 BSD 5 menit.",
          "Ocean Park BSD 10 menit.",
          "Al-Azhar, Santa Ursula 8 menit.",
        ]}
      />
    </div>
  );
};

export default Latinos;

import "./Location-pages.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";

const LocationPages = ({ map, mapAlt, pointData }) => {
  return (
    <section className="section-lokasi" id="lokasi">
      <h2 className="lokasi-title">Lokasi</h2>
      <div className="container-lokasi">
        <div className="lokasi-img">
          <img src={map} alt={mapAlt} />
        </div>
        <div className="lokasi-point">
          <ul>
            {pointData.map((item, index) => (
              <li key={index}>
                <FontAwesomeIcon icon={faLocationCrosshairs} className="icon" />
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default LocationPages;

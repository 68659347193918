import "../Pages.scss";
import heroImg from "../../Assets/Hero/NBS-hero.webp";
import aboutImg from "../../Assets/About/NBS-about.webp";
import map from "../../Assets/Map/NBS-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  typeaImages,
  typebImages,
  typecImages,
  typedImages,
} from "../../Assets/Product/Navapark Business Suites/NBSImages";
const imageMap = {
  typeaImages,
  typebImages,
  typecImages,
  typedImages,
};
const NBS = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"NAVAPARK BUSINESS SUITES"}
        imgAlt={"Navapark Business Suites"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"Navapark Business Suites About "}
        aboutTitle={"NAVAPARK BUSINESS SUITES"}
        aboutDescription={
          "Navapark Business Suites adalah gedung perkantoran elit yang terletak di kawasan BSD City, dikembangkan oleh Hongkong Land dan Sinarmas Land. Menawarkan lokasi strategis yang berseberangan dengan Green Office Park BSD, gedung ini dikelilingi oleh perusahaan ternama seperti Unilever dan Traveloka. Fasilitas yang tersedia mencakup lift, basement, dan desain modern yang mendukung kebutuhan bisnis. Dengan konsep kawasan komersial premium, Navapark Business Suites menjadi pilihan ideal untuk investasi dan pengembangan bisnis."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Navapark%20Business%20Suites)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      {/* <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN NORTH POINT TWO"}
        pointData={{
          poin1: ["Discount Promo 399JT", "Discount KPR Express 586JT"],
          poin2: [],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20Navapark%20Business%20Suites)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      /> */}
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.NBScards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Map Location"}
        pointData={[
          "The QBig BSD 5 menit.",
          "AEON Mall 10 menit.",
          "Tol AEON BSD 10 menit.",
          "ICE BSD 10 menit.",
        ]}
      />
    </div>
  );
};

export default NBS;

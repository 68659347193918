import "../Pages.scss";
import heroImg from "../../Assets/Hero/TTX-hero.jpeg";
import aboutImg from "../../Assets/About/TTX-about.jpeg";
import flyerImg from "../../Assets/Flyer/TTX-flyer.webp";
import map from "../../Assets/Map/TTX-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  primeaImages,
  primebImages,
  ultimatecImages,
  ultimatedImages,
} from "../../Assets/Product/Taman Tekno X/TTXImages";
const imageMap = {
  primeaImages,
  primebImages,
  ultimatecImages,
  ultimatedImages,
};
const TTX = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"TAMAN TEKNO X"}
        imgAlt={"Taman Tekno X Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"Taman Tekno X About Image"}
        aboutTitle={"TAMAN TEKNO X"}
        aboutDescription={
          "Taman Tekno X adalah Shophouse Gudang Serbaguna yang dapat digunakan sebagai kantor, gudang, bengkel, showroom, ekspedisi, dan lainnya dalam satu unit. Taman Tekno BSD City merupakan pelopor kompleks pergudangan dengan sistem klaster, tata letak yang fleksibel, dan perawatan yang mudah. Kompleks pergudangan ini dirancang dengan gaya modern dan eksklusif. Selain itu, Taman Tekno X memiliki akses yang mudah dan dekat dengan jalan tol, serta jalan yang lebar."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Taman%20Tekno%20X)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN TAMAN TEKNO X"}
        pointData={{
          poin1: [
            "Diskon 10%",
            "Subsidi DP",
            "Grandprize 1 unit Apartemen Southgate Altuera",
            "Saving Up to 10% + 2%",
          ],
          poin2: [],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20Taman%20Tekno%20X)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.TTXcards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Latinos Location Image"}
        pointData={[
          "Exit Toll BSD 5 menit.",
          "CBD BSD Timur 10 menit.",
          "CBD Jakarta 35 Menit.",
          "Bandara Soekarno Hatta 40 Menit.",
          "Pelabuhan Tanjung Priok 45 menit.",
        ]}
      />
    </div>
  );
};

export default TTX;

import h1 from "./Hero.png";
import h2 from "./Hero 2.png";
import h3 from "./Hero 3.png";
import h4 from "./Hero 4.png";
import h5 from "./Hero 5.png";
import h6 from "./Hero 6.png";
import h7 from "./Hero 7.png";
import h8 from "./Hero 8.png";
import h9 from "./Hero 9.png";
import h10 from "./Hero 10.png";
import h11 from "./Hero 11.png";

const hero = [h1, h2, h3, h4, h5, h6, h7, h8, h9, h10, h11];

export default hero;

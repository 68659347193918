import React from "react";
import "./About-pages.scss";

const AboutPages = ({
  aboutImg,
  aboutAlt,
  aboutTitle,
  aboutDescription,
  waLink,
}) => {
  const waOpen = () => {
    window.open(`${waLink}`, "_blank");
  };
  return (
    <div className="aboutcont" id="about">
      <div className="center">
        <img className="img" src={aboutImg} alt={aboutAlt} />
        <div className="abouttile">About Us</div>
        <div className="aboutcontent">
          <div className="title">{aboutTitle}</div>
          <div className="subtitle">
            <div className="text">{aboutDescription}</div>
            <button onClick={waOpen} className="wabutton">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPages;

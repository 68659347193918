import "./Hero.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hero from "../../Assets/Hero/index";
const Hero = () => {
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <section id="hero" className="section-hero">
      <div className="slider">
        <Slider {...settings}>
          {hero.map((image) => (
            <div className="slide">
              <img src={image} alt="about samesta parayasa" />
            </div>
          ))}
        </Slider>
      </div>
      <div className="container-hero">
        <div className="hero-description">
          <h6 className="hero-title">WELCOME TO</h6>
          <h4 className="hero-text">BSD CITY</h4>
        </div>
      </div>
    </section>
  );
};

export default Hero;

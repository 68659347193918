import "../Pages.scss";
import heroImg from "../../Assets/Hero/Xlane-hero.jpeg";
import aboutImg from "../../Assets/About/xlane-about.jpeg";
import flyerImg from "../../Assets/Flyer/xlane-flyer.webp";
import map from "../../Assets/Map/xlane-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  balconyImages,
  standartImages,
} from "../../Assets/Product/Xlane/XlaneImages";
const imageMap = {
  balconyImages,
  standartImages,
};
const Xlane = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"XLANE"}
        imgAlt={"Xlane Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"Xlane About Image"}
        aboutTitle={"XLANE"}
        aboutDescription={
          "Xlane BSD City adalah pusat bisnis bergengsi yang menawarkan ruko tiga lantai dengan desain arsitektur modern dan lingkungan yang menarik. Lokasinya strategis dengan akses mudah ke jalan tol utama, serta dekat dengan transportasi umum, seperti Stasiun Rawa Buntu. Fasilitas lengkap termasuk area parkir luas, keamanan 24 jam, dan dikelilingi oleh pusat perbelanjaan, hotel mewah, serta area residensial, menjadikannya lokasi ideal bagi para pengusaha untuk mengembangkan bisnis mereka."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(XLANE)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN XLANE"}
        pointData={{
          poin1: [
            "Tanpa DP",
            "Discount UpTo 10%",
            "Voucher elektronik UpTo 40jt",
            "Cashback UpTo 25jt",
            "Free Smart Door Lock",
          ],
          poin2: [],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20XLANE)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.Xlanecards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Xlane Location Image"}
        pointData={[
          "Kyzn SportClub BSD City 2 menit.",
          "The Barn dan Grand Lucky BSD 3 menit.",
          "Qbig BSD, ICE BSD, Genesis Global School, dan Rans Nusantara 5 menit.",
          "Universitas Prasetya Mulya 8 menit.",
          "Akses Toll dan AEON BSD 10 menit.",
          "Stasiun dan Pasar Intermoda BSD City 12 menit.",
        ]}
      />
    </div>
  );
};

export default Xlane;

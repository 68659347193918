import nu from "./NU 2.jpeg";

const balconyImages = [nu, nu];
const cornerImages = [nu, nu];
const standardImages = [nu, nu];
const standard2Images = [nu, nu];
const corner2Images = [nu, nu];

export {
  standardImages,
  standard2Images,
  cornerImages,
  corner2Images,
  balconyImages,
};

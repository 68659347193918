import "../Pages.scss";
import heroImg from "../../Assets/Hero/NPT-hero.jpeg";
import aboutImg from "../../Assets/About/NPT-about.jpeg";
import flyerImg from "../../Assets/Flyer/NPT-flyer.webp";
import map from "../../Assets/Map/NPT-map.png";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import {
  grandCornerImages,
  premiumImages,
  grandImages,
  signatureImages,
} from "../../Assets/Product/North Point Two/NPTImages";
const imageMap = {
  grandCornerImages,
  premiumImages,
  grandImages,
  signatureImages,
};
const NPT = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"NORTH POINT TWO"}
        imgAlt={"North Point Two Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"North Point Two About Image"}
        aboutTitle={"NORTH POINT TWO"}
        aboutDescription={
          "North Point TWO berada dekat dengan kawasan residensial mewah NavaPark di BSD City. Kota ini akan dikembangkan dengan konsep hunian hijau dan transportasi yang baik. Didesain dengan konsep CORe (Commerce, Office, Retail), setiap lantai dapat disewa oleh penyewa berbeda dengan akses mandiri. Fasad mewah dengan langit-langit 4m membuat lantai dasar dan pertama mudah dikenali, serta terdapat ruang ritel premium dengan lobi eksklusif untuk tamu."
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(North%20Point%20Two)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN NORTH POINT TWO"}
        pointData={{
          poin1: ["Discount Promo 399JT", "Discount KPR Express 586JT"],
          poin2: [],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20North%20Point%20Two)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.NPTcards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Latinos Location Image"}
        pointData={[
          "The QBig BSD 5 menit.",
          "AEON Mall 10 menit.",
          "Tol AEON BSD 10 menit.",
          "ICE BSD 10 menit.",
        ]}
      />
    </div>
  );
};

export default NPT;

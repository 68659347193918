import React from "react";
import "../Pages.scss";
import flyerImg from "../../Assets/Flyer/home-flyer (1).webp";
import dataProduk from "../../Section/Product/data.json";
import Hero from "../../Section/Hero/Hero";
import ScrollToAnchor from "../ScrollToAnchor";
import About from "../../Section/About/About";
import Newlaunch from "../../Section/Newlaunch/Newlaunch";
import Promo from "../../Section/Promo/Promo";
import Product from "../../Section/Product/Product";
import Tenants from "../../Section/Tenants/Tenants";
import Projects from "../../Section/Projects/Projects";
import Location from "../../Section/Location/Location";
import {
  vapImages,
  latinosImages,
  loopImages,
  vaImages,
  wvpImages,
  xlaneImages,
  nuImages,
  drImages,
  ttxImages,
  ccImages,
  nptImages,
  nbsImages,
  iconixImages,
  asterraImages,
} from "../../Assets/Product/productImages";
const imageMap = {
  vapImages,
  latinosImages,
  loopImages,
  vaImages,
  wvpImages,
  xlaneImages,
  nuImages,
  drImages,
  ttxImages,
  ccImages,
  nptImages,
  nbsImages,
  iconixImages,
  asterraImages,
};
const Home = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <Hero />
      <About />
      <Newlaunch />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN BSD CITY"}
        pointData={{
          poin1: [
            "Tanpa DP",
            "Siap Huni",
            "Free PPN 100%",
            "Cashback UpTo 80Juta",
            "Disc. Miliayaran Rupiah",
            "Subsidi DP",
            "Free IPL 12 Bulan",
          ],
          poin2: [
            "Subsidi Biaya KPR",
            "Voucher Elektornik UpTo 40Juta",
            "Grandprize 1 unit Apartemen Southgate Altuera",
            "Voucher Informa 20Juta",
            "Free Smart Door Lock ",
          ],
        }}
        waLink="https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
      />
      <Product
        productTitle="OUR COMMERCIAL"
        dataProduk={dataProduk.commercial}
        imageMap={imageMap}
      />
      <Tenants />
      <Projects />
      <Location />
    </div>
  );
};

export default Home;

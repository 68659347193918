import "./Location.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconOffice } from "../../Assets/svg/office.svg";
import { ReactComponent as IconEducation } from "../../Assets/svg/education2.svg";
import { ReactComponent as IconResidence } from "../../Assets/svg/residentials.svg";
import map from "../../Assets/map.png";
const Location = () => {
  const pointData = {
    poin1: [
      "2 Menit ke IPK School",
      "5 Menit ke BPK Penabur",
      "5 Menit ke Al-Azhar BSD School",
      "5 Menit ke Atmajaya University",
      "10 Menit ke Pelita Harapan University",
    ],
    poin2: [
      "1 Menit ke Enchante Residence",
      "2 Menit ke Zora Residence",
      "3 Menit ke Nava Park",
      "3 Menit ke Greenwich Park",
      "3 Menit ke Jadeite Residence",
    ],
    poin3: [
      "4 Menit ke Unilever Office",
      "4 Menit ke Kanggo",
      "10 Menit ke Evosys",
      "10 Menit ke Graha Telkom",
      "10 Menit ke OCBC",
    ],
  };
  return (
    <section id="location" className="section-surrounding">
      <div className="container-surrounding">
        <h2 className="title">Location</h2>
        <div className="container-image">
          <img src={map} alt="Area Sekitar BSD City" className="image" />
        </div>
        <div className="container-fasilitas">
          <div className="point">
            <div className="fasilitas">
              <IconEducation className="icon-fasilitas" />
              <span>Education</span>
            </div>
            {pointData.poin1.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-point"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="point">
            <div className="fasilitas">
              <IconResidence className="icon-fasilitas" />
              <span>Residentials</span>
            </div>
            {pointData.poin2.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-point"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div className="point">
            <div className="fasilitas">
              <IconOffice className="icon-fasilitas" />
              <span>Office Complex</span>
            </div>
            {pointData.poin3.map((item, index) => (
              <div className="loc">
                <FontAwesomeIcon
                  icon={faLocationCrosshairs}
                  className="icon-loc"
                />
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;

import "../Pages.scss";
import heroImg from "../../Assets/Hero/iconix-hero.webp";
import aboutImg from "../../Assets/About/about-iconix.webp";
import flyerImg from "../../Assets/Flyer/iconix-flyer.webp";
import map from "../../Assets/Map/iconix-map.webp";
import ScrollToAnchor from "../ScrollToAnchor";
import HeroPages from "../../Section/Hero/Hero-pages";
import AboutPages from "../../Section/About/About-pages";
import Promo from "../../Section/Promo/Promo";
import ProductPages from "../../Section/Product/Product-pages";
import LocationPages from "../../Section/Location/Location-pages";
import dataProduk from "../../Section/Product/data.json";
import iiImages from "../../Assets/Product/Iconix/iconixImages";
const imageMap = {
  iiImages,
};
const Iconix = () => {
  return (
    <div className="content-page">
      <ScrollToAnchor />
      <HeroPages
        img={heroImg}
        pageTitle={"ICONIX INFINITE"}
        imgAlt={"Iconix Infinite Hero Image"}
      />
      <AboutPages
        aboutImg={aboutImg}
        aboutAlt={"Iconix Infinite About Image"}
        aboutTitle={"ICONIX INFINITE "}
        aboutDescription={
          "Iconix Infinite adalah kompleks komersial eksklusif di BSD City yang dikembangkan oleh Sinar Mas Land, menawarkan unit studio loft premium dengan desain arsitektur European Luxury yang elegan dan modern. Dengan luas tanah 6x18 meter, setiap unit memiliki empat lantai dan dilengkapi dengan lift pribadi untuk kenyamanan akses. Berlokasi strategis di boulevard utama BSD City dekat pintu tol AEON BSD, Iconix Infinite dikelilingi oleh berbagai fasilitas seperti pusat perbelanjaan, universitas, dan perkantoran, menjadikannya pilihan ideal bagi bisnis dan investasi. "
        }
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Iconix+Infinite)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <Promo
        flyer={flyerImg}
        promoTitle={"PROMO KESELURUHAN ICONIX INFINITE"}
        pointData={{
          poin1: ["Diskon 10%", "Free DP", "Free Lift"],
          poin2: [
            "Free Bunga",
            "Cashback 60 Juta",
            "Voucher Elektronik 70 Juta",
          ],
        }}
        waLink={
          "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo+Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20(Promo%20Keseluruhan%20Iconix%20Infinite)%20BSD%20City%20Commercial?%20https://rukokavlingbsdcity.com/&type=phone_number&app_absent=0"
        }
      />
      <ProductPages
        productTitle={"SHOPHOUSE TYPE"}
        dataProduk={dataProduk.CIcards}
        imageMap={imageMap}
      />
      <LocationPages
        map={map}
        mapAlt={"Latinos Location Image"}
        pointData={[
          "Tol Serpong–Balaraja, 2 menit.",
          "Aeon Mall",
          "ICE BSD City",
          "Universitas Atma Jaya",
          "Universitas Prasetiya Mulya",
          "Intermoda BSD City",
        ]}
      />
    </div>
  );
};

export default Iconix;
